import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { StaticQuery, graphql } from "gatsby"
import { decode } from "he"

function Seo({ lang, type, seoData, dateGmt, modifiedGmt, uri }) {
  const {
    title,
    description,
    twitterTitle,
    twitterDescription,
    openGraphTitle,
    openGraphDescription,
    openGraphType,
    socialImage,
  } = seoData

  const articleMeta =
    openGraphType === "article"
      ? [
          {
            name: "article:published_time",
            content: dateGmt,
          },
          {
            name: "article:modified_time",
            content: modifiedGmt,
          },
          {
            name: "og:updated_time",
            content: modifiedGmt,
          },
        ]
      : []

  const imageMeta = (siteUrl) =>
    socialImage?.localFile
      ? [
          {
            property: "og:image",
            content: siteUrl + socialImage.localFile.publicURL,
          },
          {
            property: "og:image:width",
            content: socialImage.mediaDetails.width,
          },
          {
            property: "og:image:height",
            content: socialImage.mediaDetails.height,
          },
          {
            name: "twitter:image",
            content: siteUrl + socialImage.localFile.publicURL,
          },
          {
            name: "twitter:image:width",
            content: socialImage.mediaDetails.width,
          },
          {
            name: "twitter:image:height",
            content: socialImage.mediaDetails.height,
          },
          {
            name: "twitter:image:alt",
            content: socialImage.altText,
          },
        ]
      : []

  return (
    <StaticQuery
      query={detailsQuery}
      render={(data) => {
        const metaTitle =
          title ||
          data.wp.generalSettings.description ||
          data.site.siteMetadata.title

        const metaDescription =
          description ||
          data.wp.generalSettings.description ||
          data.site.siteMetadata.description

        const siteUrl = data.site.siteMetadata.siteUrl || ""
        return (
          <Helmet
            htmlAttributes={{
              lang,
            }}
            title={decode(metaTitle)}
            titleTemplate="%s"
            meta={[
              {
                name: "description",
                content: metaDescription,
              },
              {
                property: "og:title",
                content: openGraphTitle || title,
              },
              {
                property: "og:description",
                content: openGraphDescription || metaDescription,
              },
              {
                property: "og:type",
                content: type || openGraphType || "website",
              },
              {
                property: "og:url",
                content: siteUrl + uri,
              },
              {
                property: "og:site_name",
                content: data.wp.generalSettings.title,
              },
              {
                property: "og:locale",
                content: "en_US",
              },
              {
                name: "twitter:title",
                content: twitterTitle || title,
              },
              {
                name: "twitter:description",
                content: twitterDescription || metaDescription,
              },
              {
                name: "twitter:card",
                content: "summary_large_image",
              },
            ]
              .concat(imageMeta(siteUrl))
              .concat(articleMeta)}
          >
            <link rel="canonical" href={siteUrl + uri} />
          </Helmet>
        )
      }}
    />
  )
}

Seo.defaultProps = {
  lang: `en`,
}

Seo.propTypes = {
  lang: PropTypes.string,
}

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        author
        siteUrl
      }
    }
    wp {
      generalSettings {
        title
        description
      }
      seoSettings {
        separator
      }
    }
  }
`

export default Seo
